import { http } from "@/utils/http";
import { baseUrlApi } from "./utils";
/** 登录 */
export const getLogin = data => {
  return http.request("post", baseUrlApi("login"), { data });
};

/** 刷新`token` */
export const refreshTokenApi = data => {
  return http.request("post", baseUrlApi("refresh-token"), { data });
};
