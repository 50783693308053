// 抽离可公用的工具函数等用于系统管理页面逻辑
import { computed, ref } from "vue";
import { useDark, storageLocal } from "@pureadmin/utils";
import {
  get_brand_all,
  get_ProductCategory_all,
  get_colour_all,
  get_model_all,
  get_specifications_all,
  get_business_list,
  get_Contactunits_list,
  get_collection_list,
  get_supplier_list,
  get_Salaryitems_list
} from "@/api/document";
import { getDeptList, getUserList } from "@/api/system";
import {
  get_sceneNameOne,
  get_strategyName,
  get_customerDiagnostics_strategyName
} from "@/api/mova";
import { get_WarehouseInformation_list } from "@/api/yigoweb";
import dayjs from "dayjs";
export function usePublicHooks() {
  const { isDark } = useDark();

  const switchStyle = computed(() => {
    return {
      "--el-switch-on-color": "#6abe39",
      "--el-switch-off-color": "#e84749"
    };
  });

  const tagStyle = computed(() => {
    return status => {
      return status === 1
        ? {
            "--el-tag-text-color": isDark.value ? "#6abe39" : "#389e0d",
            "--el-tag-bg-color": isDark.value ? "#172412" : "#f6ffed",
            "--el-tag-border-color": isDark.value ? "#274a17" : "#b7eb8f"
          }
        : {
            "--el-tag-text-color": isDark.value ? "#e84749" : "#cf1322",
            "--el-tag-bg-color": isDark.value ? "#2b1316" : "#fff1f0",
            "--el-tag-border-color": isDark.value ? "#58191c" : "#ffa39e"
          };
    };
  });

  const getTagStyle = computed(() => {
    return status => {
      switch (status) {
        case 1:
          return {
            "--el-tag-text-color": isDark.value ? "#1890ff" : "#1890ff",
            "--el-tag-bg-color": isDark.value ? "#122b45" : "#e6f7ff",
            "--el-tag-border-color": isDark.value ? "#1d395c" : "#91d5ff"
          };
        case 2:
          return {
            "--el-tag-text-color": isDark.value ? "#6abe39" : "#389e0d",
            "--el-tag-bg-color": isDark.value ? "#172412" : "#f6ffed",
            "--el-tag-border-color": isDark.value ? "#274a17" : "#b7eb8f"
          };
        case 3:
          return {
            "--el-tag-text-color": isDark.value ? "#e84749" : "#cf1322",
            "--el-tag-bg-color": isDark.value ? "#2b1316" : "#fff1f0",
            "--el-tag-border-color": isDark.value ? "#58191c" : "#ffa39e"
          };
        default:
          return {};
      }
    };
  });
  function Status(value) {
    switch (value) {
      case 1:
        return "待稽核";
      case 2:
        return "已稽核";
      case 3:
        return "已红冲";
      default:
        console.log("I am an apple.");
        return "未知";
    }
  }
  const orderStatus = [
    { id: 1, name: "待稽核" },
    {
      id: 2,
      name: "已稽核"
    },
    {
      id: 3,
      name: "已红冲"
    }
  ];

  const deps = storageLocal().getItem("deps");
  function TimeClick(input) {
    // 将输入转换为 dayjs 对象
    const inputDate = dayjs(input);

    // 获取当前时间的 dayjs 对象
    const currentDate = dayjs();

    // 计算两个日期之间的天数差
    const daysDifference = currentDate.diff(inputDate, "day");
    console.log(daysDifference, deps.length * 15);
    return daysDifference < deps.length * 15;
  }
  function AA() {
    if (
      userinfo.roles.includes("admin") ||
      userinfo.roles.includes("SystemAdmin") ||
      userinfo.roles.includes("finance")
    ) {
      return true;
    } else {
      return false;
    }
  }

  const cuenchu = storageLocal();
  const userinfo = cuenchu.getItem("user-info");
  console.log(userinfo);
  const def_status = ref(userinfo.deps.length === 0 ? true : false);
  console.log(def_status);
  const userlist = cuenchu.getItem("userlist");
  //前台数组
  const AcceptanceOptions = [];
  const USERALL = [];
  for (let i = 0; i < userlist.length; i++) {
    if (userlist[i].remark.includes("前台")) {
      AcceptanceOptions.push(userlist[i]);
    }
    let ttt = { id: userlist[i].id, name: userlist[i].username };
    if (userlist[i].deptid) {
      ttt.deptid = userlist[i].deptid;
      ttt.deptname = userlist[i].deptname;
    }
    USERALL.push(ttt);
  }
  function userOptions() {
    let data = [];
    if (AA()) {
      data = USERALL;
    } else {
      for (let i = 0; i < USERALL.length; i++) {
        console.log(USERALL[i]);
        if (userinfo.deps.includes(USERALL[i].deptid)) {
          data.push(USERALL[i]);
        }
      }
    }
    return data;
  }
  function defOptions() {
    let deflist = cuenchu.getItem("depflist");
    deflist.push({ id: 0, name: "全部" });
    if (AA()) {
      return deflist;
    }
    let data = [];
    let bx = [];
    for (let i = 0; i < deflist.length; i++) {
      if (userinfo.deps.includes(deflist[i].id)) {
        data.push(deflist[i]);
      }
      if (userinfo.department == deflist[i].id) {
        bx.push(deflist[i]);
        cuenchu.setItem("coordinate", deflist[i].coordinate);
      }
    }
    if (data.length == 0) {
      return bx;
    }
    return data;
  }
  function SalarOptions() {
    let slist = cuenchu.getItem("Salaryitems");
    let data = [];
    for (let i = 0; i < slist.length; i++) {
      data.push({
        id: slist[i].id,
        name: slist[i].name
      });
    }
    return data;
  }
  return {
    /** 当前网页是否为`dark`模式 */
    isDark,
    /** 表现更鲜明的`el-switch`组件  */
    switchStyle,
    /** 表现更鲜明的`el-tag`组件  */
    tagStyle,
    getTagStyle,
    //** 订单状态*/
    orderStatus,
    /** 是否有权限选择部门*/
    def_status,
    /** 前台列表*/
    AcceptanceOptions,
    /**获取有权限操作的员工 */
    userOptions,
    /**获取有权限操作的部门 */
    defOptions,
    SalarOptions,
    /** 状态 */
    Status,
    TimeClick
  };
}

export function init_sceneNameOne() {
  get_sceneNameOne().then(res => {
    storageLocal().setItem("sceneNameOne", res.data);
  });
}

export function init_strategyName() {
  get_strategyName().then(res => {
    storageLocal().setItem("strategyName", res.data);
  });
}

export function init_customerDiagnostics_hy() {
  get_customerDiagnostics_strategyName().then(res => {
    storageLocal().setItem("customerDiagnostics_hy", res.data);
  });
}

export function init_DeptList() {
  getDeptList({ types: 1, status: 1 })
    .then(response => {
      storageLocal().setItem("depflist", response.data);
      let did = storageLocal().getItem("user-info").department;
      for (let i = 0; i < response.data.length; i++) {
        if (did == response.data[i].id) {
          storageLocal().setItem("coordinate", response.data[i].coordinate);
          break; // 找到后退出循环
        }
      }
    })
    .catch(error => {
      // 处理错误
      console.error("Error fetching department list:", error);
    });
}

export function init_supplier() {
  get_supplier_list({
    types: 1,
    status: 1,
    pageSize: 100
  })
    .then(slist => {
      storageLocal().setItem("supplierlist", slist.data.list);
    })
    .catch(error => {
      // 处理错误
      console.error("Error fetching supplierlist list:", error);
    });
}

export function init_business() {
  get_business_list({
    pageSize: 500,
    status: 1
  })
    .then(businessOptions => {
      storageLocal().setItem("businessOptions", businessOptions.data.list);
    })
    .catch(error => {
      // 处理错误
      console.error("Error fetching businessOptions list:", error);
    });
}

export function init_UserList() {
  getUserList({
    is_active: 1
  })
    .then(data => {
      console.log(data);
      let userlist = data.data.list;
      storageLocal().setItem("userlist", userlist);
      //前台数组
      let AcceptanceOptions = [];
      let USERALL = [];
      for (let i = 0; i < userlist.length; i++) {
        if (userlist[i].remark.includes("前台")) {
          AcceptanceOptions.push(userlist[i]);
        }
        let ttt = { id: userlist[i].id, name: userlist[i].username };
        if (userlist[i].deptid) {
          ttt.deptid = userlist[i].deptid;
          ttt.deptname = userlist[i].deptname;
        }
        USERALL.push(ttt);
      }
      storageLocal().setItem("AcceptanceOptions", AcceptanceOptions);
      storageLocal().setItem("USERALL", USERALL);
    })
    .catch(error => {
      // 处理错误
      console.error("Error fetching userlist list:", error);
    });
}

export function init_Contactunits_list() {
  get_Contactunits_list({
    offset: 0,
    pageSize: 500,
    status: 1
  })
    .then(Contact => {
      storageLocal().setItem("Contact", Contact.data.list);
    })
    .catch(error => {
      // 处理错误
      console.error("Error fetching Contact list:", error);
    });
}

export function init_collection() {
  get_collection_list({
    status: 1,
    types: 1
  })
    .then(CollectionOptions => {
      storageLocal().setItem("CollectionOptions", CollectionOptions.data.list);
    })
    .catch(error => {
      // 处理错误
      console.error("Error fetching CollectionOptions list:", error);
    });
}

export function init_brand() {
  get_brand_all()
    .then(brand => {
      storageLocal().setItem("brand", brand.data);
    })
    .catch(error => {
      // 处理错误
      console.error("Error fetching brand list:", error);
    });
}

export function init_ProductCategory() {
  get_ProductCategory_all()
    .then(ProductCategory => {
      storageLocal().setItem("category", ProductCategory.data);
      // console.log(ProductCategory.data);
    })
    .catch(error => {
      // 处理错误
      console.error("Error fetching ProductCategory list:", error);
    });
}

export function init_colour() {
  get_colour_all()
    .then(colour => {
      storageLocal().setItem("colour", colour.data);
    })
    .catch(error => {
      // 处理错误
      console.error("Error fetching colour list:", error);
    });
}
export function init_model() {
  get_model_all()
    .then(model => {
      storageLocal().setItem("model", model.data);
    })
    .catch(error => {
      // 处理错误
      console.error("Error fetching model list:", error);
    });
}

export function init_specifications() {
  get_specifications_all()
    .then(specifications => {
      storageLocal().setItem("specifications", specifications.data);
    })
    .catch(error => {
      // 处理错误
      console.error("Error fetching specifications list:", error);
    });
}

export function init_Salaryitems() {
  get_Salaryitems_list({}).then(Salaryitems => {
    storageLocal().setItem("Salaryitems", Salaryitems.data.list);
  });
}

export function init_WarehouseInformation() {
  get_WarehouseInformation_list({ pageSize: 500, status: 1 }).then(res => {
    storageLocal().setItem("WarehouseInformation", res.data.list);
  });
}

export function init_app_data() {
  console.log("初始化必要配置");
  try {
    init_sceneNameOne();
    init_strategyName();
    init_customerDiagnostics_hy();
    init_DeptList();
    init_supplier();
    init_business();
    init_UserList();
    init_Contactunits_list();
    init_collection();
    init_brand();
    init_ProductCategory();
    init_colour();
    init_model();
    init_specifications();
    init_Salaryitems();
    init_WarehouseInformation();
    console.log("初始化完毕");
  } catch (error) {
    console.error("初始化失败:", error);
  }
}
